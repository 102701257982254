import { OutboundIconLink } from '@begbie/ui';
import React from 'react';
import { useConstructionProject } from '../../../providers/ConstructionProjectProvider';

export interface ConstProjectSocialLinksProps {}

export const ConstProjectSocialLinks = (
  props: ConstProjectSocialLinksProps
) => {
  const { links } = useConstructionProject();
  return (
    <div className="flex space-x-2">
      {links.map((link) => (
        <OutboundIconLink key={link.url} {...link} />
      ))}
    </div>
  );
};
