import React from 'react';

export interface FooterContactItemProps {
  label: string;
  link: {
    href: string;
    label: string;
  };
  icon: React.ReactNode;
}

export const FooterContactItem = (props: FooterContactItemProps) => {
  const { icon, label, link } = props;
  return (
    <div className="flex space-x-2">
      <div className="icon text-primary flex items-center text-2xl mr-3">
        {icon}
      </div>
      <div className="content">
        <div className="label text-sm">{label}</div>
        <a className="link text-white py-2" href={link.href}>
          {link.label}
        </a>
      </div>
    </div>
  );
};
