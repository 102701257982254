import React from 'react';

export interface CustomIconWrapperProps {
  children?: React.ReactNode;
}

export const CustomIconWrapper = (props: CustomIconWrapperProps) => {
  const { children } = props;
  return <div className="w-5 lg:w-8">{children}</div>;
};
