import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ManHole from '../assets/Manhole';
import TreeTrim from '../assets/TreeTrimming';
import { CustomIconWrapper } from '../components/CustomIconWrapper';
import { SeasonTile } from '../components/SeasonTile';
import { ServiceItem } from '../components/ServiceItem';

export interface SummerTileProps { }

export const SummerTile = (props: SummerTileProps) => {
  const { } = props;
  return (
    <SeasonTile
      id={'leto'}
      title={'Letná údržba'}
      imagePosition="left"
      // reference={{
      //   logo: <BBErb />,
      //   titlePrefix: 'Mesto',
      //   title: 'Banská Bystrica',
      //   content:
      //     'Očakávame, že naozaj tieto služby prinesú pre obyvateľov novú kvalitu. Zatiaľ sme s nimi spokojní!',
      // }}
      image={
        <StaticImage
          src={'../assets/images/summer.png'}
          className="h-full  rounded-lg shadow-2xl"
          placeholder="blurred"
          quality={90}
          alt={''}
        />
      }
      bgIcon={
        <i className="fi fi-rr-sun  absolute -top-20 right-20" />
      }
      items={[
        <ServiceItem
          key="mechanism"
          icon={<i className=" fi-rr-road" />}
          title={'Strojné čistenie'}

          description={
            `Letné čistenie ciest, chodníkov, parkovísk a areálov za použitia rôznej špeciálnej modernej mechanizácie. `
          }
        />,
        <ServiceItem
          key="sweep"
          title={'Ručné čistenie'}
          icon={<i className=" fi-rr-broom" />}
          description={
            <>
              Ručné zametanie a čistenie neprístupných plôch, autobusových zástavok, lávok, prechodov a podobne.
            </>
          }
        />,
        <ServiceItem
          key="sweep"
          title={'Kropenie komunikácií'}
          icon={<i className=" fi-rr-raindrops" />}
          description={
            `V prípade suchého počasia poskytujeme kropenie ciest a chodníkov ako aj iných spevnených plôch.`
          }
        />,

        <ServiceItem
          key="cleaning"
          icon={<i className="fi fi-rr-soap"></i>}
          title={'Dezinfekcia zastávok, parkov, lavičiek'}
          description={
            `Dezinfekcia zástaviek, parkov, lavičiek, zábradlí, smetných košov čistou 100 stupňovou vodou.`
          }
        />,
        <ServiceItem
          key="manhole"
          icon={
            <CustomIconWrapper>
              <ManHole />
            </CustomIconWrapper>
          }
          title={'Čistenie vpustí a kanalizácii'}
          description={
            `Čistenie uličných vpustov s pôvodným bahenným košom aj s výmenou bahenného koša. Prečistenie kanalizačnej prípojky uličných vpustov vysokotlakým čistením, krtkovaním, preplachom. Čistenie rigolou.`
          }
        />,

        <ServiceItem
          key="snow"
          icon={
            <CustomIconWrapper>
              <TreeTrim />
            </CustomIconWrapper>
          }
          title={'Údržba zelene'}
          description={
            `Letný rez krov a letný rez výmladkov. Kosenie verejných a súkromných plôch so zberom a bez zberu. Kosenie redurálnych porastov. Hrabanie, zber a odvoz biologicky rozložitelného odpadu.
            Polievanie zelene.`
          }
        />,
      ]}
    />
  );
};
