import { GrFacebookOption } from '@react-icons/all-files/gr/GrFacebookOption';
import React, { ReactNode } from 'react';

export interface OutboundIconLinkProps {
  url: string;
  destination: any;
}

export const destinationIconMap: Record<any, ReactNode> = {
  facebook: <GrFacebookOption />,
  instagram: <i className=" fi-brands-instagram" />,
  web: <i className=" fi-rr-globe  " />,
  youtube: <i className=" fi-brands-youtube" />,
};

export const OutboundIconLink = (props: OutboundIconLinkProps) => {
  const { url, destination } = props;
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary p-4 opacity-40 hover:opacity-90 "
    >
      {destinationIconMap[destination]}
    </a>
  );
};
