import { Button } from '@begbie/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { api } from '../../../../lib/api/api';
import { Input, TextArea } from '../../form';
import { SuccessDialog } from './SuccessDialog';

const schema = yup
  .object({
    name: yup.string().required('Toto pole je povinné'),
    company: yup.string().required('Toto pole je povinné'),
    email: yup
      .string()
      .email('Zadajte prosím správny email')
      .required('Zadajte aj Váš email nech vám máme kam odpísať.'),
    phone: yup
      .string()
      .matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        'Zadajte prosím správne telefónne číslo'
      )
      .required('Zadajte prosím telef'),
    message: yup.string().required('Toto pole je povinné'),
    agreement: yup
      .boolean()
      .oneOf([true], 'Musíte súhlasiť so spracovaním osobných údajov'),
  })
  .required();

export interface ContactFormProps {
  category: string;
  messagePlaceholder?: string;
}

export const ContactForm = (props: ContactFormProps) => {
  const { category } = props;

  const { handleSubmit, control, reset, register, formState } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
      agreement: false,
    },
  });

  const onSubmit = (data) => {
    api
      .post('/form-sumbissions', {
        data: {
          data,
          category,
        },
      })
      .then((res) => {
        setSuccessModalOpen(true);
        reset();
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const [successModalOpen, setSuccessModalOpen] = React.useState(false);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-10 lg:p-20 w-full flex-col "
        id="contact-form"
      >
        <header className="flex items-end space-x-2 text-2xl md:text-3xl xl:text-4xl mb-12">
          <h2 className="text-white font-medium">
            Kontaktný formulár <span className="text-primary">.</span>
          </h2>
        </header>
        <div className="form-items grid grid-cols-2 gap-x-12 gap-y-4 md:gap-y-8">
          <Input
            name={'name'}
            control={control}
            label={'Meno'}
            className={'col-span-2 sm:col-span-1'}
          />
          <Input
            control={control}
            name={'company'}
            label={'Firma'}
            className={'col-span-2 sm:col-span-1'}
          />
          <Input
            name={'phone'}
            label={'Telefón'}
            control={control}
            className={'col-span-2 sm:col-span-1'}
          />
          <Input
            control={control}
            name={'email'}
            label={'Email'}
            className={'col-span-2 sm:col-span-1'}
          />
          <TextArea
            control={control}
            label={'Správa'}
            name={'message'}
            placeholder={props.messagePlaceholder}
            className="col-span-2"
          />
          <div className="actions mt-6 col-span-2 flex justify-start flex-1 flex-col space-y-6">
            <div className="flex flex-col">
              <div>
                {' '}
                <input
                  id="link-checkbox"
                  type="checkbox"
                  className="w-4 h-4 text-primary bg-gray-100 rounded border-gray-300 focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  {...register('agreement')}
                />
                <label htmlFor="link-checkbox" className="ml-2 text-sm ">
                  Odoslaním požiadavky súhlasíte so spracovaním osobných údajov
                </label>
              </div>

              {formState.errors['agreement'] && (
                <div className="text-xs text-red-300 mt-2">
                  {formState.errors['agreement'].message}
                </div>
              )}
            </div>
            <Button type="submit" glow={false} className="flex-1">
              <span>Odoslať požiadavku</span>
              <i className=" fi-rr-paper-plane flex" />
            </Button>
          </div>
        </div>
      </form>
      <SuccessDialog
        open={successModalOpen}
        onClose={function (): void {
          setSuccessModalOpen(false);
        }}
      />
    </>
  );
};
