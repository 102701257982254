import { Container } from '@begbie/ui';
import React from 'react';
import { SeasonLink } from './components/SeasonLink';

export interface SeasonLinksProps { }

export const SeasonLinks = (props: SeasonLinksProps) => {
  return (
    <section className="py-32">
      <Container>
        <div className="title text-center text-gray-400 text-sm opacity-40 font-light mb-16  md:hidden">
          Vyberte ročné obdobie
        </div>
        <div className="grid gap-14 md:gap-20 grid-cols-2 md:grid-cols-4 w-10/12 mx-auto">
          <SeasonLink
            href={'#zima'}
            title={'Zima'}
            icon={<i className="fi fi-rr-snowflake" />}
          />
          <SeasonLink
            href={'#jar'}
            title={'Jar'}
            icon={<i className="fi fi-rr-flower-tulip" />}
          />
          <SeasonLink
            href={'#leto'}
            title={'Leto'}
            icon={<i className="fi fi-rr-sun" />}
          />
          <SeasonLink
            href={'#jesen'}
            title={'Jeseň'}
            icon={<i className="fi fi-rr-leaf" />}
          />
        </div>
      </Container>
    </section>
  );
};
