import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContactUsForm } from '../../shared/ContactUsForm/ContactUsForm';

export interface VehiclesContactFormProps {
  placeholder?: string;
}

export const VehiclesContactForm = (props: VehiclesContactFormProps) => {
  const { placeholder = 'Dobrý deň, mal by som záujem o prenájom vozidla...' } =
    props;
  const {
    strapiVehicle: { contacts },
  } = useStaticQuery(graphql`
    query getVehiclesContacts {
      strapiVehicle {
        contacts {
          title
          description
          contactInfo {
            value
            type
          }
        }
      }
    }
  `);
  return (
    <ContactUsForm
      header={{
        title: 'Vypožičajte si tento stroj',
        subtitle:
          `Neváhajte nás kontaktovať, radi Vám zodpovieme všetky Vaše otázky a vybavíme s Vami prenájom vozidla.`,
      }}
      placeholder={placeholder}
      category="vehicles"
      contacts={contacts}
    />
  );
};
