import React from 'react';
import { useController } from 'react-hook-form';

export interface TextAreaProps {
  label: string;
  name: string;
  placeholder?: string;
  className?: string;
  control: any;
}

export const TextArea = (props: TextAreaProps) => {
  const { label, name, control, placeholder, className, ...restOfProps } =
    props;

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      <label
        htmlFor={`contact-form-${name}`}
        className="block text-xs text-white opacity-80 "
      >
        {label}
      </label>
      <textarea
        onChange={onChange} // send value to hook form
        onBlur={onBlur} // notify when input is touched/blur
        value={value} // input value
        name={name} // send down the input name
        ref={ref}
        id={`contact-form-${name}`}
        rows={3}
        className="mt-2 focus:ring-primary focus:border-primary flex flex-1 w-full shadow-sm text-sm border-dark rounded-md bg-opacity-5 bg-white text-white lg:text-md md:py-4 md:px-6 font-light"
        placeholder={placeholder}
        defaultValue={''}
        {...restOfProps}
      />
      {error && (
        <div className="text-xs text-red-300 mt-2">{error['message']}</div>
      )}
    </div>
  );
};
