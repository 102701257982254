import { Button, Container } from '@begbie/ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { useConstructionProject } from '../../providers/ConstructionProjectProvider';
import SvgScroll from './../../../../../lib/assets/Scroll';
import { ConstProjectSocialLinks } from './components/ConstProjectSocialLinks';
import * as heroStyles from './styles/hero.module.scss';

export interface ConstructionProjectHeroProps {}

export const ConstructionProjectHero = (
  props: ConstructionProjectHeroProps
) => {
  const project = useConstructionProject();

  const projectSplitName = useMemo(() => {
    const projectNameParts = project.name.split(' ');
    const cursor = Math.floor(projectNameParts.length / 2);
    let firstPart = projectNameParts.slice(0, cursor).join(' ');
    let secondPart = projectNameParts.slice(cursor).join(' ');
    return [firstPart, secondPart];
  }, [project]);

  return (
    <section className="h-screen flex items-center relative md:overflow-hidden flex-col md:justify-center">
      <Container>
        {/* Dark Bg */}
        <div className={heroStyles.background} />
        {/* Image */}
        <div className={heroStyles.heroImage}>
          <div className="relative w-full md:max-h-[60rem] h-[24rem] overflow-hidden  md:h-[70%] rounded-b-3xl ">
            <GatsbyImage
              alt={''}
              imgClassName="rounded-b-3xl overflow-hidden"
              className="h-full rounded-b-3xl md:rounded-3xl overflow-hidden relative"
              image={project.image.localFile.childImageSharp.gatsbyImageData}
            />
            {/* <div className="overlay absolute top-0 bottom-0 left-0 right-0 bg-primary opacity-10 rounded-3xl"></div> */}
          </div>
        </div>

        {/* Project Info */}
        <div className={heroStyles.projectInfo}>
          {/* HEADER */}
          <h1 className={heroStyles.projectInfoHeader}>
            <span>{projectSplitName[0]}</span>
            <span className="text-primary">{projectSplitName[1]}.</span>
          </h1>

          <p className={heroStyles.projectInfoDescription}>
            {project.description}
          </p>
          {/* Social Links */}
          {project.links && project.links.length > 0 && (
            <div className={heroStyles.projectInfoSocialLinks}>
              <ConstProjectSocialLinks />
            </div>
          )}
          {/* CTA */}
          <div className={heroStyles.actions}>
            <a
              href="#contact-us-section"
              className="flex flex-1 md:flex-grow-0"
            >
              <Button className=" flex flex-1 md:flex-none">
                Objednať si stavbu
              </Button>
            </a>
          </div>
        </div>
      </Container>
      <div className={`${heroStyles.heroMouseScrollWheel} scroll`}>
        <SvgScroll className="w-6" />
      </div>
    </section>
  );
};
