import { StrapiSeo } from '@begbie/ui';
import { graphql } from 'gatsby';
import React from 'react';
import { Layout } from '../../components/layouts/Layout';
import { VehiclesContactForm } from '../../components/vehicles/components/VehiclesContactForm';
import { VehiclesHero } from '../../components/vehicles/components/VehiclesHero';
import { VehiclesVehicleList } from '../../components/vehicles/components/VehiclesVehicleList';

export interface indexProps {
  data: any;
}

export const query = graphql`
  query getVehiclePageInfo {
    strapiVehicle {
      seo {
       ...SeoFragment
      }
    }
  }
`;

export const VehiclesPage = (props: indexProps) => {
  const {
    data: { strapiVehicle },
  } = props;
  return (
    <Layout
      fullHeader
      header={{
        cta: {
          to: '#vehicle-list',
          label: 'Prenajať si vozidlo',
        },
      }}
    >
      <StrapiSeo seo={strapiVehicle.seo} />
      <VehiclesHero />
      <VehiclesVehicleList />
      <VehiclesContactForm />
    </Layout>
  );
};

export default VehiclesPage;
