import clsx from 'clsx';
import React, { useState } from 'react';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import ReactPlayer from 'react-player/lazy';
import { Container } from '../../container/Container';
import {
  SectionHeader,
  SectionHeaderProps,
} from '../../section/SectionHeader/SectionHeader';
import { useDebounce } from '../../utils/useDebouncedState';

export interface VideoSectionProps {
  header?: SectionHeaderProps;
  videoUrl: string;
}

export const VideoSection = (props: VideoSectionProps) => {
  const { header, videoUrl } = props;
  const [playing, setPlaying] = useState(false);
  const played = useDebounce(playing, 700);

  return (
    <Container className="page-section">
      {header && <SectionHeader {...header} />}
      <div className="md:px-0">
        <div className="video-wrapper mx-auto relative overflow-hidden rounded-3xl shadow-2xl md:mt-0">
          <ReactPlayer
            controls={true}
            playing={playing}
            width={'100%'}
            height={'100%'}
            style={{
              position: 'relative',
              zIndex: played ? 100 : 0,
            }}
            onPause={() => setPlaying(false)}
            url={`${process.env['GATSBY_STRAPI_API_URL']}${videoUrl}`}
          />
          <div className="absolute top-0 bottom-0 right-0 left-0 h-full w-full flex justify-center items-center rounded-3xl overflow-hidden ">
            <div
              className={clsx({
                'bg-dark': true,
                ' absolute top-0 bottom-0 right-0 left-0 h-full w-full transition-all duration-500 ease-in-out z-0': true,
                'opacity-20': !playing,
                'opacity-0': playing,
              })}
            />
            <div
              className={clsx({
                'bg-dark': true,
                ' absolute top-0 bottom-0 right-0 left-0 h-full w-full  z-0  transition-all duration-500 ease-in-out': true,
                'opacity-20': !playing,
                'opacity-0': playing,
              })}
            ></div>
            {/* <div className={`${styles.overlay} bg-dark`} /> */}
            <div
              onClick={() => setPlaying(true)}
              className={clsx({
                'relative play-btn text-6xl text-white rounded-full shadow-2xl hover:shadow-white cursor-pointer transition-all duration-300 ease-in-out': true,

                'opacity-0': playing,
                'opacity-100': !playing,
              })}
            >
              <BsFillPlayCircleFill />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
