import clsx from 'clsx';
import React, { ReactNode } from 'react';
import './button.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: ReactNode | ReactNode[];
  glow?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { children, glow = true, className = '', ...restOfProps } = props;
  return (
    <button
      className={clsx({
        'bg-primary text-background py-3.5 px-8 rounded duration-300 flex items-center justify-center space-x-3':
          true,
        'btn-glow': glow,
        [className]: true,
      })}
      {...restOfProps}
    >
      {children}
    </button>
  );
};
