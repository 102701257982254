import React from 'react';
import { HeaderNavigation } from './HeaderNavigation';
import { HeaderSingle } from './HeaderSingle';

export enum HeaderVariant {
  Navigation = 'navigation',
  Plain = 'plain',
}
export interface HeaderProps {
  variant?: HeaderVariant;
  routes?: {
    to: string;
    label: string;
  }[];
}

const variantRenderMap: Record<HeaderVariant, JSX.Element> = {
  navigation: <HeaderNavigation routes={[]} />,
  plain: <HeaderSingle />,
};

export const Header = (props: HeaderProps) => {
  const { routes = [], variant = HeaderVariant.Plain } = props;

  return (
    <header className="h-28 flex items-center absolute top-0 left-0 right-0 z-50">
      <HeaderNavigation routes={routes} />
    </header>
  );
};
