import clsx from 'clsx';
import React from 'react';

export interface SectionHeaderProps {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  light?: boolean;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const { title, subtitle, light, ...restOfProps } = props;
  return (
    <header className=" flex flex-col space-y-4 mb-12" {...restOfProps}>
      <h2
        className={clsx({
          'text-4xl font-bold': true,
          'text-secondary': !light,
          'text-white opacity-20': light,
        })}
      >
        {title}
        <span className="text-primary">.</span>
      </h2>
      {subtitle && (
        <p
          className={clsx({
            'text-lg font-light w-full lg:max-w-[40rem] text-secondaryText':
              true,
            'text-white opacity-20': light,
          })}
        >
          {subtitle}
        </p>
      )}
    </header>
  );
};
