import { StatsSectionLoadable } from '@begbie/ui';
import React from 'react';
import { useConstructionProject } from '../../providers/ConstructionProjectProvider';

export interface ConstructionProjectStatsSectionProps {}

export const ConstructionProjectStatsSection = (
  props: ConstructionProjectStatsSectionProps
) => {
  const { stats } = useConstructionProject();

  return <StatsSectionLoadable stats={stats} />;
};
