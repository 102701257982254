import { GallerySection } from '@begbie/ui';
import React from 'react';
import { useOfferedVehicle } from '../../providers/OfferedVehicleProvider';

export interface VehicleGalleryProps { }

export const OfferedVehicleGallery = (props: VehicleGalleryProps) => {
  const { } = props;

  const { gallerySection } = useOfferedVehicle();
  return (
    <>
      <GallerySection
        images={gallerySection.images.map((image) => image)}
        header={{
          title: gallerySection.header.title,
          subtitle: gallerySection.header.subtitle.data.subtitle,
        }}
      />
    </>
  );
};
