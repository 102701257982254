export interface Route {}

export const routes = {
  construction: {
    to: '/stavby/',
    label: 'Stavby',
  },
  maintenance: {
    to: '/udrzba/',
    label: 'Údržba',
  },
  vehicles: {
    to: '/prenajom-vozidiel/',
    label: 'Prenájom vozidiel',
  },
};

export const generateConstructionProjectRoute = (projectSlug: string) =>
  `/stavby/${projectSlug}`;

export const generateOfferedVehicleRoute = (vehicleSlug: string) =>
  `/prenajom-vozidiel/${vehicleSlug}`;
