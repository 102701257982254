import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { SeasonTile } from '../components/SeasonTile';
import { ServiceItem } from '../components/ServiceItem';

export interface WinterTileProps { }

export const WinterTile = (props: WinterTileProps) => {
  const { } = props;
  return (
    <SeasonTile
      id={'zima'}
      title={'Zimná údržba'}
      // reference={{
      //   logo: <ZvErb />,
      //   titlePrefix: 'Mesto',
      //   title: 'Zvolen',
      //   content:
      //     'Očakávame, že naozaj tieto služby prinesú pre obyvateľov novú kvalitu. Zatiaľ sme s nimi spokojní!',
      // }}
      image={
        <StaticImage
          placeholder="blurred"
          src={'../assets/images/winter.png'}
          className="h-full rounded-lg shadow-2xl"
          quality={90}
          alt={''}
        />
      }
      bgIcon={<i className="fi fi-rr-snowflake top-0 right-0 absolute" />}
      items={[
        <ServiceItem
          key="sweep"
          icon={<i className="fi  fi-rr-snowflake"></i>}

          title={'Odstranovanie snehu'}
          description={
            'Na cestách, chodníkoch, parkoviskách a iných plochách odstranujeme sneh pluhovaním, snežnou frézou alebo zametacou metlou. '
          }
        />,
        <ServiceItem
          key="snow"
          icon={<i className="fi  fi-rr-truck-side"></i>}
          title={'Odhŕňanie a odvoz snehu'}
          description={
            `Nakladanie a odvoz snehu z udržiavaných priestorov.`
          }
        />,
        <ServiceItem
          key="layers"
          icon={<i className="fi fi-rr-layers"></i>}
          title={'Posyp inertným a chemickým materiálom'}
          description={
            `Pri posypo používame inertný material - kamennú drvu (frakcia 3-6), chemický materiál - NaCl2, Zeolit.`
          }
        />,
        <ServiceItem
          key="cleaning"
          icon={<i className="fi fi-rr-broom" />}
          title={'Ručná zimná údržba'}
          description={
            `Odstraňovanie snehu, ľadu a ručný posyp chodníkov, prechodov pre chodcov, zástaviek, uličných vpustov, lávok a podobne.`
          }
        />,
      ]}
    />
  );
};
