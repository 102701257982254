import { StrapiSeo } from '@begbie/ui';
import { graphql } from 'gatsby';
import React from 'react';
import { ConstructionContactSection } from '../../components/construction/ConstructionContactSection/ConstructionContactSection';
import { ConstructionHero } from '../../components/construction/hero/ConstructionHero';
import { OurProjects } from '../../components/construction/OurProjects/OurProjects';
import { Layout } from '../../components/layouts/Layout';

export interface indexProps { }

export const query = graphql`
query ConstructionPage {
  strapiConstruction {
    seo {
      ...SeoFragment
    }
  }
}
`

export const ConstructionPage = (props: indexProps) => {
  const {
    data: {
      strapiConstruction: {
        seo
      }
    }
  } = props;
  return (
    <Layout>
      <StrapiSeo seo={seo} />
      <ConstructionHero />
      <div className="mt-12 space-y-24 lg:space-y-40">
        <OurProjects />
        <ConstructionContactSection />
      </div>
    </Layout>
  );
};

export default ConstructionPage;
