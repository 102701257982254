import { graphql } from 'gatsby';
import { Layout } from '../components/layouts/Layout';
import ReactMarkdown from 'react-markdown';
import { ContentRenderer } from '../components/shared/ContentRenderer/ContentRenderer';
import { Container } from '@begbie/ui';
import { SectionWithHighlightedTitle } from '../components/shared/SectionWithHighlightedTitle/SectionWithHighlightedTitle';
import { StrapiSeo } from '@begbie/ui';
import { InvoiceInfo } from '../components/companyInfo/InvoiceInfo';
import { ContactsInfo } from '../components/companyInfo/ContactsInfo';

export interface CompanyInfoPageProps {
  data: any;
}

export const query = graphql`
  query CompanyInfoPageQuery {
    strapiCompanyInformation {
      pageTitle {
        subtitle
        title
        highlightedTitle
      }
      invoiceInformation {
        title
        businessId
        taxtId
        vatNumber
        accountNumber
      }
      contacts {
        contactInfo {
          type
          value
        }
        title
      }
      address
      operationalTime
      seo {
        ...SeoFragment
      }
    }
  }
`;

export const CompanyInfoPage = (props: CompanyInfoPageProps) => {
  const { data } = props;
  const invoiceInfo = data?.strapiCompanyInformation.invoiceInformation;
  const contacts = data?.strapiCompanyInformation.contacts;
  return (
    <Layout>
      <StrapiSeo seo={data.strapiCompanyInformation.seo} />
      <SectionWithHighlightedTitle
        title={data?.strapiCompanyInformation.pageTitle.title}
        highlightedTitle={
          data?.strapiCompanyInformation.pageTitle.highlightedTitle
        }
        subtitle={data?.strapiCompanyInformation.pageTitle.subtitle}
      />
      <Container>
        <div className="flex md:flex-row flex-col md:items-center justify-center gap-10">
          {/*INVOICES INFO */}
          <div className="md:w-1/2">
            <InvoiceInfo invoiceInfo={invoiceInfo} />
          </div>
          {/*CONTACTS INFO */}
          <div className="md:w-1/2">
            <ContactsInfo
              title={contacts.title}
              address={data?.strapiCompanyInformation.address}
              operationalTime={data?.strapiCompanyInformation.operationalTime}
              contactsInfo={contacts}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default CompanyInfoPage;
