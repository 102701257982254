import React from 'react';
import { AutumnTile } from './tiles/AutumnTile';
import { SpringTile } from './tiles/SpringTile';
import { SummerTile } from './tiles/SummerTile';
import { WinterTile } from './tiles/WinterTile';

export interface SeasonsProps {}

export const Seasons = (props: SeasonsProps) => {
  const {} = props;
  return (
    <section className="">
      <WinterTile />
      <SpringTile />
      <SummerTile />
      <AutumnTile />
    </section>
  );
};
