import { Container } from '@begbie/ui';
import React from 'react';
import { useOfferedVehicles } from '../hooks/useOfferedVehicles';
import { VehicleCategory } from './VehicleCategory';

export interface VehiclesVehicleListProps {}

export const VehiclesVehicleList = (props: VehiclesVehicleListProps) => {
  const {} = props;
  const categories = useOfferedVehicles();

  return (
    <Container className="page-section space-y-40" id="vehicle-list">
      {categories.map((category) => (
        <VehicleCategory
          key={category.name}
          name={category.name}
          vehicles={category.offered_vehicles.map((vehicle) => ({
            ...vehicle,
            image: (vehicle as any).image.localFile.childImageSharp
              .gatsbyImageData,
          }))}
        />
      ))}
    </Container>
  );
};
