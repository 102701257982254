import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgManhole = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.833 14c0 5.983-4.85 10.833-10.833 10.833-5.983 0-10.833-4.85-10.833-10.833C3.167 8.017 8.017 3.167 14 3.167c5.983 0 10.833 4.85 10.833 10.833ZM27 14c0 7.18-5.82 13-13 13S1 21.18 1 14 6.82 1 14 1s13 5.82 13 13ZM8.511 12.09a1.011 1.011 0 0 0-2.022 0v4.731a1.011 1.011 0 0 0 2.022 0v-4.73Zm2.239-4.26c.558 0 1.011.452 1.011 1.01v10.87a1.011 1.011 0 0 1-2.022 0V8.84c0-.558.453-1.01 1.011-1.01Zm8.739 4.26a1.011 1.011 0 0 1 2.022 0v4.876a1.011 1.011 0 0 1-2.022 0V12.09ZM17.25 7.83c-.558 0-1.011.452-1.011 1.01v11.134a1.011 1.011 0 1 0 2.022 0V8.841c0-.559-.453-1.011-1.011-1.011Zm-2.239-1.336a1.011 1.011 0 0 0-2.022 0v15.889a1.011 1.011 0 1 0 2.022 0V6.492Z"
      fill="#54A947"
    />
  </svg>
);

const Memo = memo(SvgManhole);
export default Memo;
