import { Button, Container, useSplitName } from '@begbie/ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import SvgScroll from '../../../../lib/assets/Scroll';
import { useOfferedVehicle } from '../../providers/OfferedVehicleProvider';
import * as heroStyles from './styles/hero.module.scss';

export interface OfferedVehicleHeroProps {}

export const OfferedVehicleHero = (props: OfferedVehicleHeroProps) => {
  const { name, description, image, prices } = useOfferedVehicle();
  const [nameFirstPart, nameSecondPart] = useSplitName(name);

  return (
    <section className="h-screen min-h-[60rem] flex items-center relative md:overflow-hidden flex-col md:justify-center">
      <Container>
        {/* Dark Bg */}
        <div className={heroStyles.background} />
        {/* Image */}
        <div className={heroStyles.heroImage}>
          <div className="relative w-full max-h-[70rem] ">
            <GatsbyImage
              alt={''}
              imgClassName="rounded-3xl"
              className="h-full -scale-x-100"
              image={image.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>

        {/* Project Info */}
        <div className={heroStyles.projectInfo}>
          {/* HEADER */}
          <h1 className={heroStyles.projectInfoHeader}>
            <span>{nameFirstPart}</span>{' '}
            <span className="text-primary">{nameSecondPart}.</span>
          </h1>

          <p className={heroStyles.projectInfoDescription}>{description}</p>
          <div
            className={
              'mt-8 md:mt-16 grid grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-6 md:w-2/6 pl-0'
            }
          >
            {prices.map((price) => (
              <div className=" ">
                <h3 className="text-primary md:text-4xl text-3xl font-semibold">
                  <span>{price.value}</span>
                  <span>{price.unit}</span>
                </h3>
                <div className="flex overflow-hidden text-sm break-all">
                  {price.label}
                </div>
              </div>
            ))}
          </div>
          {/* CTA */}
          <div className={heroStyles.actions}>
            <a href="#contact-us-section" className="flex flex-1">
              <Button className=" flex flex-1 md:flex-none">
                Vypožičať si stroj
              </Button>
            </a>
          </div>
        </div>
      </Container>
      <div className={`${heroStyles.heroMouseScrollWheel} scroll`}>
        <SvgScroll className="w-6" />
      </div>
    </section>
  );
};
