import React from 'react';
import { ReactComponent as Logo } from '../../../images/logo-full-white.svg';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const {} = props;
  return (
    <header className="flex justify-center  h-20  items-center absolute top-0 left-0 right-0 z-50">
      <div className="logo w-16 lg:w-32">
        <Logo />
      </div>
    </header>
  );
};
