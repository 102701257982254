import { Link } from 'gatsby';
import React from 'react';
import { ReactComponent as Logo } from './assets/logo.svg';

export interface MobileNavigationProps {
  className?: string;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
  routes: {
    to: string;
    label: string;
  }[];
}

export const MobileNavigation = (props: MobileNavigationProps) => {
  const { routes = [], className, setMobileMenuIsOpen } = props;

  return (
    <div
      className={`bg-background z-50 top-0 w-screen h-screen fixed py-4 flex justify-center items-center duration-[400ms] min-h-[200px] overflow-y-scroll delay-100	ease-in-out	transition-all ${className}`}
    >
      {/* LOGO  */}
      <div className="absolute left-8 top-10 flex items-center">
        <Link to="/">
          <div className="logo w-24 lg:w-28">
            <Logo />
          </div>
        </Link>
      </div>

      <div className=" max-h-full w-full">
        {/* LINKS  */}
        <div className="flex justify-between items-center flex-col  gap-y-10 pb-10 pt-24  w-full ">
          {routes.map((route) => (
            <Link
              key={route.to}
              to={route.to}
              className="text-text font-medium  text-2xl leading-4"
              activeClassName={'text-gray-800'}
            >
              <span className="">{route.label}</span>
            </Link>
          ))}
          {/* CONTACT US BUTTON  */}
          <div
            className="md:hidden flex right justify-end items-center"
            onClick={() => {
              setMobileMenuIsOpen(false);
            }}
          >
            <a href="#contact-us-section">
              <div className="text-2xl leading-4 text-primary font-semibold">
                Kontakt.
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
