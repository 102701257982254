import { Button } from '@begbie/ui';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

export interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SuccessDialog = (props: SuccessDialogProps) => {
  const { open, onClose } = props;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block text-center w-full max-w-md p-10 my-8 overflow-hidden  align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="mb-6">
                <i className="text-5xl text-primary fi-rr-check" />
              </div>
              <Dialog.Title
                as="h3"
                className="text-xl font-medium leading-6 text-gray-900"
              >
                Formulár úspešne odoslaný
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Formulár bol úspešne odoslaný. Ozveme sa Vám čo najskôr to
                  bude možné.
                </p>
              </div>

              <div className="mt-8 flex justify-center">
                <Button onClick={onClose}>Ok</Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
