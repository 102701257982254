import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  placeholder?: string;
}

export const Input = (props: InputProps) => {
  const { label, name, placeholder, ...restOfProps } = props;
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  return (
    <div className="flex flex-col flex-1">
      <label
        htmlFor={`contact-form-${name}`}
        className="block text-xs text-white opacity-80 "
      >
        {label}
      </label>
      <input
        onChange={onChange} // send value to hook form
        onBlur={onBlur} // notify when input is touched/blur
        value={value} // input value
        name={name} // send down the input name
        ref={ref}
        type="text"
        placeholder={placeholder}
        id={`contact-form-${name}`}
        autoComplete="family-name"
        className="mt-2 focus:ring-primary focus:border-primary flex flex-1 w-full shadow-sm sm:text-sm border-dark rounded-md bg-opacity-5 bg-white text-white lg:text-md md:py-4 md:px-6 font-light"
        // {...restOfProps}
      />
    </div>
  );
};
