import React, { ReactNode } from 'react';

export interface ContainerProps {
  children: ReactNode | ReactNode[];
  className?: string;
  id?: string;
}

export const Container = (props: ContainerProps) => {
  const { children, className } = props;
  return (
    <section {...props} className={'container mx-auto px-8 ' + className}>
      {children}
    </section>
  );
};
