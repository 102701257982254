import React from 'react';
import { ConstructionProject } from '../model/construction-project.model';

export interface ConstructionProjectProviderProps {
  project: ConstructionProject.Project;
  children: React.ReactNode;
}

export const ConstructionProjectContext =
  React.createContext<ConstructionProject.Project>(null);

export const useConstructionProject = () => {
  const context = React.useContext(ConstructionProjectContext);
  if (context === undefined) {
    throw new Error(
      'useConstructionProject must be used within a ConstructionProjectProvider'
    );
  }
  return context;
};

export const ConstructionProjectProvider = (
  props: ConstructionProjectProviderProps
) => {
  const {} = props;
  return (
    <ConstructionProjectContext.Provider value={props.project}>
      {props.children}
    </ConstructionProjectContext.Provider>
  );
};
