import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

export const useProjects = () => {
  const { allStrapiProject } = useStaticQuery(graphql`
    query ourProjects {
      allStrapiProject {
        nodes {
          name
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 20
                  formats: WEBP
                )
              }
            }
          }
        }
      }
    }
  `);

  return allStrapiProject?.nodes.map((project) => ({
    name: project.name,
    slug: project.slug,
    image: getImage(project.image.localFile.childImageSharp.gatsbyImageData),
  }));
};
