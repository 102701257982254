import React, { ReactNode } from 'react';

export interface InfoWrapperProps {
  label: string;
  value?: string;
  children?: React.ReactNode[];
}

export const InfoWrapper = (props: InfoWrapperProps) => {
  const { label, value, children } = props;
  return (
    <p className="flex flex-col md:flex-row">
      <label className="font-bold block">{`${label}:`} &nbsp; </label>
      {value && <span className="text-sm md:text-base block">{value}</span>}

      {children && <>{children}</>}
    </p>
  );
};

export default InfoWrapper;