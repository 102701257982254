import { Button, Container } from '@begbie/ui';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import SvgScroll from '../../../lib/assets/Scroll';
import './styles/hero.css';
import * as heroStyles from './styles/hero.module.css';

export interface HeroProps {}

export const ConstructionHero = (props: HeroProps) => {
  const {} = props;
  return (
    <section className="h-screen  min-h-[30rem] flex items-center relative overflow-hidden">
      <Container>
        <div className="image absolute top-0 bottom-0 right-0 h-screen">
          <div className={heroStyles.background}>
            <div className={heroStyles.heroImage}>
              <StaticImage
                placeholder="tracedSVG"
                quality={90}
                src={'./assets/crane.png'}
                alt={'crane'}
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <h1 className="text-5xl md:text-6xl text-secondary font-bold">
            Stavby<span className="text-primary">.</span>
          </h1>
          <p className="mt-6 md:mt-8 text-lg font-light">
            Zrealizujeme Vám stavby rôzneho charakteru, kde medzi naše dominanty
            patria: rekonštrukcie nemocníc, výstavba nemocničných zariadení,
            bytových komplexov, rodinných domov, polyfunkčných objektov,
            priemyselných stavieb a pod.
          </p>

          <div className="actions mt-10 md:mt-20">
            <a href="#contact-us-section">
              <Button>Objednať stavbu</Button>
            </a>
          </div>
        </div>
      </Container>
      <div className="scroll absolute bottom-6 right-0 left-0 mx-auto w-10 md:opacity-20">
        <SvgScroll className="w-6" />
      </div>
    </section>
  );
};
