import { graphql } from 'gatsby';
import { Layout } from '../components/layouts/Layout';
import ReactMarkdown from 'react-markdown';
import { ContentRenderer } from '../components/shared/ContentRenderer/ContentRenderer';
import { Container } from '@begbie/ui';
import { SectionWithHighlightedTitle } from '../components/shared/SectionWithHighlightedTitle/SectionWithHighlightedTitle';
import { StrapiSeo } from '@begbie/ui';
import { InfoSectionTitle } from './InfoSectionTitle';
import { InfoWrapper } from './InfoWrapper';

export interface InvoiceInfoProps {
  invoiceInfo: [];
}

export const InvoiceInfo = (props: InvoiceInfoProps) => {
  const { invoiceInfo } = props;
  return (
    <>
      <InfoSectionTitle title={invoiceInfo.title} />
      <div className="flex mt-2 gap-y-2 flex-col">
        <InfoWrapper label={'IČO'} value={invoiceInfo.businessId} />
        <InfoWrapper label={'DIČ'} value={invoiceInfo.taxtId} />
        <InfoWrapper label={'IČ DPH'} value={invoiceInfo.vatNumber} />
        <InfoWrapper label={'Číslo účtu'} value={invoiceInfo.accountNumber} />
      </div>
    </>
  );
};

export default InvoiceInfo;