import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { SingleStatistic } from '../model/stats.model';

export interface SInleStatisticProps extends SingleStatistic {}

function isFloat(n: number): boolean {
  if (!n) {
    return false;
  }
  return !isNaN(n % 1) && n % 1 !== 0;
}

export const StatisticSingle = (props: SInleStatisticProps) => {
  const { label, value, suffix } = props;

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return (
          <article>
            <h3 className="flex space-x-3 text-primary text-3xl md:text-5xl font-bold">
              <span>
                {isVisible ? (
                  <CountUp
                    duration={3}
                    decimal="."
                    decimals={isFloat(parseFloat(value)) ? 2 : 0}
                    useEasing
                    start={parseFloat(value) / 1.05}
                    end={parseFloat(value)}
                  />
                ) : null}
              </span>
              <div className="suffix">{suffix}</div>
            </h3>
            <p className="text-text opacity-70 text-sm md:text-base">{label}</p>
          </article>
        );
      }}
    </VisibilitySensor>
  );
};
