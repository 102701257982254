import { graphql } from 'gatsby';
import { Layout } from '../components/layouts/Layout';
import ReactMarkdown from 'react-markdown';
import { ContentRenderer } from '../components/shared/ContentRenderer/ContentRenderer';
import { Container } from '@begbie/ui';
import { SectionWithHighlightedTitle } from '../components/shared/SectionWithHighlightedTitle/SectionWithHighlightedTitle';
import { StrapiSeo } from '@begbie/ui';
import { InfoSectionTitle } from './InfoSectionTitle';
import { InfoWrapper } from './InfoWrapper';

export interface ContactsInfoProps {
  contactsInfo: [];
  address?: string;
  operationalTime?: string;
  title: string;
}

export const ContactsInfo = (props: ContactsInfoProps) => {
  const { contactsInfo, address, operationalTime, title } = props;
  return (
    <>
      <InfoSectionTitle title={title} />
      <div className="flex mt-2 gap-y-2 flex-col">
        <InfoWrapper label={'Adressa'} value={address} />

        {contactsInfo.contactInfo.map((contact, index) => (
          <div key={index}>
            {contact.type === 'email' && (
              <InfoWrapper label={'Email'}>
                <a
                  href={`mailto:${contact.value}`}
                  className="hover:opacity-70"
                >
                  {contact.value}
                </a>
              </InfoWrapper>
            )}

            {contact.type === 'phone' && (
              <InfoWrapper label={'Mobil'}>
                <a href={`tel:${contact.value}`} className="hover:opacity-70">
                  {contact.value}
                </a>
              </InfoWrapper>
            )}
          </div>
        ))}
        <InfoWrapper label={'Prevádzková doba'} value={operationalTime} />
      </div>
    </>
  );
};

export default ContactsInfo;