import { StrapiSeo } from '@begbie/ui';
import { graphql } from 'gatsby';
import { ConstructionContactSection } from '../../components/construction/ConstructionContactSection/ConstructionContactSection';
import { ConstructionProjectGalleriesSection } from '../../components/construction/Project/components/ConstructionProjectGalleriesSection';
import { ConstructionProjectGallerySection } from '../../components/construction/Project/components/ConstructionProjectGallerySection';
import { ConstructionProjectHero } from '../../components/construction/Project/components/ConstructionProjectHero/ConstructionProjectHero';
import { ConstructionProjectStatsSection } from '../../components/construction/Project/components/ConstructionProjectStatsSectionConstructionProjectStatsSection/ConstructionProjectStatsSection';
import { ConstructionProjectVideoSection } from '../../components/construction/Project/components/ConstructionProjectVideoSection';
import { ConstructionProjectProvider } from '../../components/construction/Project/providers/ConstructionProjectProvider';
import { Layout } from '../../components/layouts/Layout';

export interface Project {
  name: string;
  slug: string;
}

export interface ProjectPageProps {
  data: {
    strapiProject: any;
  };
}

export const query = graphql`
  fragment GalleryFragment on STRAPI__COMPONENT_SECTIONS_GALLERY_SECTION {
    header {
      title
      subtitle {
        data {
          subtitle
        }
      }
    }
    images {
      url
      formats {
        large {
          url
        }
      }
      localFile {
        url
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
  query($slug: String!) {
    strapiProject(slug: { eq: $slug }) {
      name
      description
      stats {
        label
        value
        suffix
      }
      links {
        destination
        url
      }
      video {
        header {
          title
          subtitle {
            data {
              subtitle
            }
          }
        }
        video {
          url
        }
      }
      seo {
        ...SeoFragment
      }

      image {
        url
        formats {
          large {
            url
          }
        }
        localFile {
          url
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 90
            )
          }
        }
      }
      gallery {
        ...GalleryFragment
      }
      galleries {
        ...GalleryFragment
      }
    }
  }
`;

export const ProjectPage = (props: ProjectPageProps) => {
  const {
    data: { strapiProject: project },
  } = props;

  return (
    <Layout headerColor="light">
      <ConstructionProjectProvider project={project}>
        <StrapiSeo seo={project.seo} />
        <ConstructionProjectHero />
        <div className="spacer flex flex-col">
          <ConstructionProjectStatsSection />
          <ConstructionProjectGallerySection />
          <ConstructionProjectGalleriesSection />
          <ConstructionProjectVideoSection />
          <ConstructionContactSection />
        </div>
      </ConstructionProjectProvider>
    </Layout>
  );
};

export default ProjectPage;
