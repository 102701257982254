import {
  FooterLoadable,
  Header,
  LayoutConfigContextProvider,
} from '@begbie/ui';
import loadable from '@loadable/component';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { routes } from '../../lib/routes';
import './global.css';

const Footer = loadable(
  () =>
    /* webpackChunkName: "footer" */
    import('../../../../../libs/ui/src/lib/footer/Footer'),
  {
    resolveComponent: (mod) => mod.Footer,
    ssr: true,
  }
);

// const ParallaxProvider = loadable(
//   () => import('react-scroll-parallax').then((mod) => mod.ParallaxProvider),
//   { ssr: true }
// );
export interface LayoutProps {
  children?: ReactNode | ReactNode[];
  /**
   * Fake header placed when we need the rest of the content to be displayed normally
   * or in other words we dont have a hero that is overflowing the header
   */
  fullHeader?: boolean;
  header?: {
    cta?: {
      to: string;
      label: string;
    };
  };
  headerColor?: string;
}

export const Layout = (props: LayoutProps) => {
  const { fullHeader = false, header, headerColor } = props;

  return (
    <>
      <Helmet>
        <link
          href="/uicons-regular-rounded/css/uicons-regular-rounded.css"
          rel="stylesheet"
        />
        <link href="/uicons-brands/css/uicons-brands.css" rel="stylesheet" />
      </Helmet>

      <LayoutConfigContextProvider headerColor={headerColor}>
        <Header routes={Object.values(routes)} {...header} />
        {fullHeader && <div className="h-20" />}
        <main>{props.children}</main>
        <FooterLoadable />
      </LayoutConfigContextProvider>
    </>
  );
};
