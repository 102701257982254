import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Container } from '../container/Container';
import { useLayoutConfigContext } from '../LayoutConfigContext/LayoutConfigContextProvider';
import { ReactComponent as LogoWhite } from '../shared/assets/logo-all-white.svg';
import { ReactComponent as Logo } from './assets/logo.svg';
import { MobileNavigation } from './MobileNavigation';

export interface HeaderNavigationProps {
  routes: {
    to: string;
    label: string;
  }[];
}

export const HeaderNavigation = (props: HeaderNavigationProps) => {
  const { routes = [] } = props;
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState<boolean>(false);

  const { headerColor } = useLayoutConfigContext();

  //ADD STYLES TO BODY ACCORDING TO THIS MENU IS OPEN OR NOT
  useEffect(() => {
    setTimeout(() => {
      if (mobileMenuIsOpen) {
        document.body.style.height = '100vh';
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.height = '';
        document.body.style.overflowY = 'initial';
      }
    }, 230);
  }, [mobileMenuIsOpen]);

  const handleChangeMenuOpenedClicked = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  return (
    <Container className="grid grid-cols-3">
      {/* LOGO  */}
      <div className="left flex items-center">
        <Link to="/">
          <div className="logo w-24 lg:w-28">
            <div className="mobile-logo md:hidden">
              {headerColor === 'light' ? <LogoWhite /> : <Logo />}
            </div>
            <div className="desktop-logo hidden md:flex">
              <Logo />
            </div>
          </div>
        </Link>
      </div>
      {/* LINKS  */}
      <div className="hidden md:flex center space-x-10 justify-center items-center text-sm">
        {routes.map((route) => (
          <Link
            to={route.to}
            key={route.label}
            className="text-gray-400 font-medium"
            activeClassName={'text-gray-800'}
          >
            <span className="">{route.label}</span>
          </Link>
        ))}
      </div>
      {/* CONTACT US BUTTON  */}
      <div className="hidden md:flex right justify-end items-center">
        <a href="#contact-us-section">
          <div className="text-md text-primary font-semibold">Kontakt.</div>
        </a>
      </div>

      {/* MOBILE MENU ICON  */}
      <div className="relative z-[55] col-span-2 place-self-end self-center	">
        <span className="" onClick={handleChangeMenuOpenedClicked}>
          {mobileMenuIsOpen ? (
            <i className=" fi fi-rr-cross text-base flex" />
          ) : (
            <i
              className={`fi fi-rr-menu-burger self-center md:hidden text-xl ${
                headerColor === 'light' ? 'text-white' : ''
              }`}
            />
          )}
        </span>
      </div>

      {/* MOBILE MENU  */}
      <MobileNavigation
        className={clsx({
          'translate-x-0 left-0': mobileMenuIsOpen,
          '-translate-x-full -left-full ': !mobileMenuIsOpen,
        })}
        routes={Object.values(routes)}
        setMobileMenuIsOpen={setMobileMenuIsOpen}
      />
    </Container>
  );
};
