import { Container } from '@begbie/ui';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { SeasonsQuoteCard } from './SeasonsQuoteCard';
import { ServiceItemProps } from './ServiceItem';

export interface SeasonTileProps {
  title: string;
  id: string;
  image: React.ReactNode;
  imagePosition?: 'left' | 'right';
  reference?: {
    logo: ReactNode;
    titlePrefix: string;
    title: string;
    content: string;
  };
  bgIcon: ReactNode;
  items: React.ReactNode[];
}

export const SeasonTile = (props: SeasonTileProps) => {
  const {
    title,
    image,
    reference,
    bgIcon,
    items,
    id,
    imagePosition = 'left',
  } = props;
  return (
    <Container>
      <article
        id={id}
        className={clsx({
          'flex py-10 lg:py-20 gap-16 flex-col ': true,
          'lg:flex-row': imagePosition === 'left',
          'lg:flex-row-reverse': imagePosition === 'right',
        })}
      >
        <div
          className={clsx({
            'image-pane w-full lg:w-1/2 order-1 lg:order-none flex justify-center  items-center':
              true,
            'lg:justify-end': imagePosition === 'right',
            'lg:justify-start': imagePosition === 'left',
          })}
        >
          <div className="image w-full md:w-[35rem] lg:h-[40rem] md:h-[30rem] h-[20rem] relative">
            {image}
            {reference && (
              <SeasonsQuoteCard
                authorPrefix={reference.titlePrefix}
                author={reference.title}
                className={clsx({
                  'absolute md:w-[30rem] lg:w-[30rem] mx-3 -bottom-8 md:top-10':
                    true,
                  'xl:top-20 2xl:-right-20 md:-right-10':
                    imagePosition === 'left',
                  'xl:top-20 2xl:-left-20 md:-left-10':
                    imagePosition === 'right',
                })}
                logo={reference.logo}
                text={reference.content}
              />
            )}
          </div>
        </div>
        <div className="content-pane w-full lg:w-1/2 flex items-center">
          <div className="content relative">
            <h2 className="text-3xl text-dark font-medium inline">
              {title}
              <span className="text-primary">.</span>
            </h2>
            <div className="content mt-10 space-y-8">
              {items.map((item: ServiceItemProps, index: number) => (
                <div key={index}>{item}</div>
              ))}
            </div>
            <div className="bg-icon text-[12rem] text-primary opacity-5">
              {bgIcon}
            </div>
          </div>
        </div>
      </article>
    </Container>
  );
};
