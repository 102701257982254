import { StrapiSeo } from '@begbie/ui';
import { graphql } from 'gatsby';
import React from 'react';
import { Header } from '../components/home/Header/Header';
import { Router } from '../components/home/Router/Router';

export const query = graphql`
  query Homepage {
    strapiGlobal {
      seo {
        ...SeoFragment
      }
    }
  }
`;

export const Index = ({ data }) => {
  return (
    <>
      <StrapiSeo seo={data.strapiGlobal.seo} />
      <Header />
      <Router />
    </>
  );
};

export default Index;
