import { VideoSectionLoadable } from '@begbie/ui';
import { useConstructionProject } from '../providers/ConstructionProjectProvider';

export interface ConstructionProjectVideoSectionProps {}

export const ConstructionProjectVideoSection = (
  props: ConstructionProjectVideoSectionProps
) => {
  const { video } = useConstructionProject();

  if (!video) {
    return null;
  }

  return (
    <VideoSectionLoadable
      header={{
        title: video?.header?.title,
        subtitle: video?.header?.subtitle?.data?.subtitle,
      }}
      videoUrl={video.video.url}
    />
  );
};
