import { GallerySectionLoadable } from '@begbie/ui';
import { useConstructionProject } from '../providers/ConstructionProjectProvider';

export interface ConstructionProjectGalleriesSectionProps {}

export const ConstructionProjectGalleriesSection = (
  props: ConstructionProjectGalleriesSectionProps
) => {
  const { galleries } = useConstructionProject();
  return (
    <>
      {galleries.map((gallery) => (
        <GallerySectionLoadable
          header={{
            title: gallery.header.title,
            subtitle: gallery.header.subtitle.data.subtitle,
          }}
          images={gallery.images.map((image) => image)}
        />
      ))}
    </>
  );
};
