import { useMemo } from 'react';

export const useSplitName = (name: string) => {
  return useMemo(() => {
    const projectNameParts = name.split(' ');
    const cursor = Math.floor(projectNameParts.length / 2);
    let firstPart = projectNameParts.slice(0, cursor).join(' ');
    let secondPart = projectNameParts.slice(cursor).join(' ');
    return [firstPart, secondPart];
  }, [name]);
};
