import { VehicleCardProps } from '@begbie/ui';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ReactNode } from 'react';

export interface MaintenanceVehicle {
  name: string;
  description: string;
  brand: string;
  image: ReactNode;
  slug: string;
}

export const useMaintenanceVehicles = (): VehicleCardProps[] => {
  const {
    allStrapiVehicleCategory: { nodes },
  } = useStaticQuery(graphql`
    query getMaintenanceVehicles {
      allStrapiVehicleCategory(filter: { slug: { eq: "udrzba" } }) {
        nodes {
          name
          offered_vehicles {
            name
            slug
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 40
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes[0].offered_vehicles;

  return [
    {
      name: 'HAKO Citymaster 600',
      slug: 'elise-900',
      description: `Vďaka menšej šírke a enormnej ovládateľnosti s ním prenikneme aj do
      najtesnejších miest.`,
      image: (
        <div className="p-6">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/citymaster600.png'}
            alt={'Kubota'}
          />
        </div>
      ),
      brand: 'Hako',
    },
    {
      name: 'Ravo 5 iSeries',
      slug: 'elise-900',
      description: `Štandard v čistení miest a to s ohľadom na životné prostredie.`,
      image: (
        <div className="p-8">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/ravo.png'}
            alt={'man'}
          />
        </div>
      ),
      brand: 'Hako',
    },
    {
      name: 'Mathieu MC 210',
      slug: 'elise-900',
      description: `Štandard v čistení miest a to s ohľadom na životné prostredie.`,
      image: (
        <div className="p-8">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/mathieu.png'}
            alt={'man'}
          />
        </div>
      ),
      brand: 'Hako',
    },
    {
      name: 'Holder x45i',
      slug: 'elise-900',
      description: 'Nový model s benzínovým motorom, pre tichšie upratovanie.',
      image: (
        <div className="p-8">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/holder.png'}
            alt={'man'}
          />
        </div>
      ),
      brand: 'Hako',
    },
    {
      name: 'Multicar M27 Compact',
      slug: 'elise-900',
      description: `Multicar používame najmä pri čistení vpustí.`,
      image: (
        <StaticImage
          placeholder="tracedSVG"
          src={'../assets/multicar.png'}
          alt={'Multicar'}
        />
      ),
      brand: 'Hako',
    },
    {
      name: 'Kubota g26-II',
      slug: 'elise-900',
      description: `Vynikajúci strih i v extrémnych pracovných podmienkach.`,
      image: (
        <div className="py-8">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/kubota.png'}
            alt={'Multicar'}
          />
        </div>
      ),
      brand: 'Kubota',
    },
    {
      name: 'Elise 900',
      slug: 'elise-900',
      description: `Výkonný elektrický nakladač, s nulovým dopadom na životné prostredie.`,
      image: (
        <StaticImage
          placeholder="tracedSVG"
          src={'../assets/elise.png'}
          alt={'Multicar'}
        />
      ),
      brand: 'Elise',
    },
    {
      name: 'Iveco Trakker',
      slug: 'elise-900',
      description: `Náš spoľahlivý zimný posýpač a odhŕňač snehu.`,
      image: (
        <div className="p-4">
          <StaticImage
            className=""
            placeholder="tracedSVG"
            src={'../assets/iveco.png'}
            alt={'Iveco Trakker'}
          />
        </div>
      ),
      brand: 'Iveco',
    },
    {
      name: 'Man',
      description: 'Náš spoľahlivý zimný posýpač a odhŕňač snehu.',
      slug: 'elise-900',
      image: (
        <div className="py-8">
          <StaticImage
            placeholder="tracedSVG"
            src={'../assets/man.png'}
            alt={'man'}
          />
        </div>
      ),
      brand: 'Hako',
    },
  ];
};
