import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContactUsForm } from '../../shared/ContactUsForm/ContactUsForm';

export interface ContactSectionProps { }

export const ConstructionContactSection = (props: ContactSectionProps) => {
  const { } = props;

  const {
    strapiConstruction: { contacts },
  } = useStaticQuery(graphql`
    query getConstructionContacts {
      strapiConstruction {
        contacts {
          title
          description
          contactInfo {
            value
            type
          }
        }
      }
    }
  `);

  return (
    <ContactUsForm
      header={{
        title: 'Kontaktujte nás',
        subtitle:
          `Kontaktujte nás v čom by sme Vám vedeli pomôcť.`
      }}
      category="construction"
      placeholder="Dobrý deň, mal by som záujem o stavebné práce..."
      contacts={contacts}
    />
  );
};
