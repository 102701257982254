import { StrapiSeo } from '@begbie/ui';
import { graphql } from 'gatsby';
import { Layout } from '../../components/layouts/Layout';
import { OfferedVehicleGallery } from '../../components/vehicles/components/OfferedVehicleGallery/OfferedVehicleGallery';
import { OfferedVehicleHero } from '../../components/vehicles/components/OfferedVehicleHero/OfferedVehicleHero';
import { VehiclesContactForm } from '../../components/vehicles/components/VehiclesContactForm';
import { OfferedVehicleProvider } from '../../components/vehicles/providers/OfferedVehicleProvider';

export interface SingleOfferedVehiclePageProps {
  data: any;
}

export const query = graphql`
  query getOfferedVehiclePageInfo($slug: String!) {
    strapiOfferedVehicle(slug: { eq: $slug }) {
      name
      description
      prices {
        label
        unit
        value
      }
      seo {
        ...SeoFragment
      }
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              quality: 60
            )
          }
        }
      }
      gallerySection {
        header {
          title
          subtitle {
            data {
              subtitle
            }
          }
        }
        images {
          url
          formats {
            large {
              url
            }
          }
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 25
              )
            }
          }
        }
      }
    }
  }
`;

export const SingleOfferedVehiclePage = (
  props: SingleOfferedVehiclePageProps
) => {
  const {
    data: { strapiOfferedVehicle },
  } = props;

  return (
    <Layout>
      <OfferedVehicleProvider vehicle={strapiOfferedVehicle}>
        <StrapiSeo seo={strapiOfferedVehicle.seo} />
        <OfferedVehicleHero />
        <OfferedVehicleGallery />
        <VehiclesContactForm
          placeholder={`Dobrý deň, mal by som záujem o prenájom vozidla ${strapiOfferedVehicle.name} ...`}
        />
      </OfferedVehicleProvider>
    </Layout>
  );
};

export default SingleOfferedVehiclePage;
