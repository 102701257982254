import React from 'react';

export interface ServiceItemProps {
  icon: React.ReactNode;
  title: string;
  description: string | React.ReactNode;
}

export const ServiceItem = (props: ServiceItemProps) => {
  const { icon, title, description } = props;
  return (
    <article className="flex space-x-6">
      <div className="icon text-primary mt-1.5 lg:mt-0 text-[1.2rem] lg:text-[1.8rem]">
        {icon}
      </div>
      <div className="content">
        <h3 className="title font-normal text-lg lg:text-xl mb-1 text-secondary">
          {title}
        </h3>
        <p className="description font-light text-secondaryText text-sm lg:text-base">
          {description}
        </p>
      </div>
    </article>
  );
};
