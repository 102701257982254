import { Container } from '@begbie/ui';
import React from 'react';

export interface VehiclesHeroProps {}

export const VehiclesHero = (props: VehiclesHeroProps) => {
  const {} = props;
  return (
    <Container className="py-16 md:py-32 justify-center flex" id="vehicles">
      <header className="flex justify-center flex-col items-center">
        <h1 className="text-5xl md:text-6xl text-secondary  text-center font-bold">
          Prenájom <span className="text-primary">strojov.</span>
        </h1>
        <p className="mt-6 md:mt-8 text-lg font-light w-full md:w-2/3 text-center">
          Ponúkame prenájom stavebných strojov a strojov údržby podľa vašej
          potreby.
        </p>
      </header>
    </Container>
  );
};
