import { useEffect, useState } from 'react';
import { ContactItemProps } from '../components/shared/ContactUsForm/components/ContactUsContactCard';
import { ContactItem } from './model/contact.model';

const mappersBasedOnType = {
  email: (contact: ContactItem): ContactItemProps => ({
    href: `mailto:${contact.value}`,
    icon: <i className="fi-rr-envelope flex" />,
    label: contact.value,
  }),
  phone: (contact: ContactItem): ContactItemProps => ({
    href: `tel:${contact.value}`,
    icon: <i className=" fi-rr-phone-call flex" />,
    label: contact.value,
  }),
};

export const mapContactItemToContactItemProps = (
  contactItem: ContactItem
): ContactItemProps => {
  return mappersBasedOnType[contactItem.type](contactItem);
};

// Hook
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
