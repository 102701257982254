import React from 'react';
import { Layout } from '../components/layouts/Layout';

const NotFoundPage = () => (
  <Layout>
    <div className="center h-screen flex justify-center ">
      <h1 className="flex flex-col items-center font-bold mt-64 text-center">
        <span className="flex text-primary text-[5rem] md:text-[10rem]">
          404
        </span>
        <span className="flex text-text font-semibold text-[1.5rem] md:text-[3rem]">
          Stránka nebola nájdená
        </span>
      </h1>
    </div>
  </Layout>
);

export default NotFoundPage;
