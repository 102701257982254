import React from 'react';
import { StrapiVehicle } from '../model/vehicle.mode';

export interface ConstructionProjectProviderProps {
  vehicle: StrapiVehicle.RootObject;
  children: React.ReactNode;
}

export const OfferedVehicleContext =
  React.createContext<StrapiVehicle.RootObject>(null);

export const useOfferedVehicle = () => {
  const context = React.useContext(OfferedVehicleContext);
  if (context === undefined) {
    throw new Error(
      'useOfferedVehicle must be used within a OfferedVehicleContext'
    );
  }
  return context;
};

export const OfferedVehicleProvider = (
  props: ConstructionProjectProviderProps
) => {
  const {} = props;
  return (
    <OfferedVehicleContext.Provider value={props.vehicle}>
      {props.children}
    </OfferedVehicleContext.Provider>
  );
};
