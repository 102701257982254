import { Link } from 'gatsby';
import React from 'react';

export interface FooterLinksSectionProps {
  title: string;
  links?: {
    href: string;
    title: string;
  }[];
  children?: React.ReactNode;
}

export const FooterLink = (props: { to: string; title: string }) => {
  return (
    <Link
      to={props.to}
      className="block text-white opacity-40 hover:opacity-80 duration-200 text-base"
    >
      {props.title}
    </Link>
  );
};

export const FooterLinksSection = (props: FooterLinksSectionProps) => {
  const { title, links = [], children } = props;
  return (
    <article>
      <h3 className="text-2xl font-bold">
        <span className="text-white ">{title}</span>
        <span className="text-primary">.</span>
      </h3>
      <div className="links space-y-2 mt-3">
        {links.map((link) => (
          <FooterLink key={link.href} to={link.href} title={link.title} />
        ))}
      </div>
      <div className="content">{children}</div>
    </article>
  );
};
