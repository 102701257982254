import { Container } from '@begbie/ui';
import loadable from '@loadable/component';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail';
import { HiOutlinePhone } from '@react-icons/all-files/hi/HiOutlinePhone';
import { Link } from 'gatsby';
import React from 'react';
import { ReactComponent as LogoWhite } from '../shared/assets/logo-all-white.svg';
import { FooterContactItem } from './components/FooterContactItem';
import {
  FooterLinksSection,
  FooterLink,
} from './components/FooterLinksSection';
import { FooterSocials } from './components/FooterSocials';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  React.useEffect(() => {}, []);
  return (
    <footer className="py-28 bg-dark mt-24">
      <Container className="flex justify-between flex-col md:flex-row gap-x-10">
        <section className="left">
          <article className="logo w-52 opacity-80 hover:opacity-100 duration-200 mb-2">
            <Link to="/">
              <LogoWhite />
            </Link>
          </article>
          <FooterSocials />
          <p className="text-white opacity-60 lg:w-96 font-thin text-base mt-6 mb-16">
            Poskytujeme celoročnú údržbu aby Vaše mesto bolo krásne čísté a s
            profesionálnym tímom s dlhoročnými skúsenosťami realizujeme stavby
            čím budujeme Vaše sny.
          </p>
        </section>
        {/*LINKS*/}
        <section className="flex flex-wrap gap-20">
          <FooterLinksSection
            title={'Stavby'}
            links={[
              {
                href: '/stavby#our-projects',
                title: 'Náše stavby',
              },
              {
                title: 'Kontakt',
                href: '/stavby#contact-us-section',
              },
              // {
              //   title: 'Aktuality',
              //   href: '/stavby/aktuality',
              // },
              // {
              //   title: 'Blog',
              //   href: '/stavby/blog',
              // },
            ]}
          />
          <FooterLinksSection
            title={'Udržba'}
            links={[
              {
                title: 'Služby',
                href: '/udrzba#seasons',
              },
              // {
              //   title: 'Referencie',
              //   href: '/udrzba/referencie',
              // },
              // {
              //   title: 'Kontakt',
              //   href: '/udrzba/kontakt',
              // },
              // {
              //   title: 'Aktuality',
              //   href: '/udrzba/aktuality',
              // },
              // {
              //   title: 'Blog',
              //   href: '/udrzba/blog',
              // },
              {
                title: 'Stroje',
                href: '/udrzba#stroje',
              },
            ]}
          />

          <FooterLinksSection
            title={'Prenájom strojov'}
            links={[
              {
                title: 'Stroje',
                href: '/prenajom-vozidiel',
              },
              {
                title: 'Kontakt',
                href: '/prenajom-vozidiel#contact-us-section',
              },
              // {
              //   title: 'Skládka',
              //   href: '/odpady/skladka',
              // },
            ]}
          />

          <FooterLinksSection title={'Kontakt'}>
            <div className="space-y-3">
               <FooterLink to={'/firemne-udaje'} title={'Firemné údaje'} />
              <FooterLink
                to={'/ochrana-osobnych-udajov'}
                title={'Ochrana osobných údajov'}
              />
              <FooterContactItem
                label={'Kancelária'}
                link={{
                  href: 'tel:+421 910 531 011',
                  label: '+421 910 531 011',
                }}
                icon={<HiOutlinePhone />}
              />
              <FooterContactItem
                label={'Email'}
                link={{
                  href: 'mailto:kancelaria@begbie.sk',
                  label: 'kancelaria@begbie.sk  ',
                }}
                icon={<HiOutlineMail />}
              />
           
            </div>
          </FooterLinksSection>
        </section>
      </Container>
    </footer>
  );
};

export const FooterLoadable = loadable(
  () =>
    /* webpackChunkName: "footer" */
    import('./Footer').then((mod) => mod.Footer),
  {
    ssr: false,
  }
);