import { Link } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import  './styles.css';


export interface ContentRendererProps {
  className?: string;
  source: string;
}


const LinkRenderer = (props: any) => {
  const { href, children } = props;

  if (
    href.startsWith("http") ||
    href.startsWith("mailto") ||
    href.startsWith("tel:")
  ) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return <Link to={href}>{children}</Link>;
};

export const ContentRenderer = (props: ContentRendererProps) => {
  const { className = "", source } = props;

  return (
    <div className={`content-renderer ${className}`}>
      <ReactMarkdown
        children={source}
        // If the url start with either http or https, the image is hosted somewhere else,
        // if hover it starts with / we need to prepend the strapi api url to the url
        transformImageUri={(uri) =>
          uri.startsWith("http")
            ? uri
            : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
        }
        components={{
          link: LinkRenderer,
        }}
      />
    </div>
  );
};