import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgBb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 105 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.109.527C-.016.857-.036 18.03.062 38.69c.157 32.48.273 38.03.86 41.028 3.396 17.337 13.415 30.898 28.456 38.516 7.532 3.814 14.413 5.425 23.173 5.425 8.761 0 15.642-1.611 23.173-5.425 15.206-7.702 25.273-21.46 28.499-38.949.643-3.483.717-7.768.722-41.513l.006-37.629L52.643.034C7.897-.058.303.014.11.527ZM103.49 8.646v7.015H1.613V1.63H103.49v7.016Zm0 30.4v7.229H1.613V31.819H103.49v7.228Zm0 30.614v7.228H1.613V62.432H103.49v7.228Zm-5.01 23.63c0 .404-3.195 5.78-4.675 7.866-.76 1.071-2.255 2.936-3.323 4.145l-1.942 2.2H16.563l-1.942-2.2c-1.068-1.209-2.563-3.074-3.323-4.145-1.48-2.086-4.675-7.462-4.675-7.866 0-.135 20.668-.245 45.928-.245 25.26 0 45.929.11 45.929.245Z"
      fill="#54A947"
    />
  </svg>
);

const Memo = memo(SvgBb);
export default Memo;
