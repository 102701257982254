import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Container } from '../../container/Container';
import {
  SectionHeader,
  SectionHeaderProps,
} from '../../section/SectionHeader/SectionHeader';

export interface GallerySectionProps {
  header?: SectionHeaderProps;
  images: any[];
}

export const GallerySection = (props: GallerySectionProps) => {
  const { header, images } = props;

  const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false);
  const [lightBoxIndex, setLightBoxIndex] = React.useState(0);

  return (
    <Container className="gallery page-section">
      {header && <SectionHeader {...header} />}
      <div className="gallery grid grid-cols-2 lg:grid-cols-3 gap-6 md:gap-12">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery__item aspect-4/3 rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-xl relative group"
            onClick={() => {
              setIsLightBoxOpen(true);
              setLightBoxIndex(index);
            }}
          >
            <div className="overlay hidden md:flex absolute top-0 bottom-0 left-0 right-0 bg-dark opacity-30 z-10 group-hover:opacity-0"></div>
            <GatsbyImage
              className="h-full rounded-xl overflow-hidden"
              imgClassName="rounded-xl overflow-hidden h-full"
              image={getImage(image.localFile)}
              alt={''}
            />
          </div>
        ))}
      </div>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={
            process.env.GATSBY_STRAPI_API_URL + images[lightBoxIndex].url
          }
          closeLabel="close"
          prevLabel="prev"
          nextSrc={
            process.env.GATSBY_STRAPI_API_URL +
            images[(lightBoxIndex + 1) % images.length].url
          }
          prevSrc={
            process.env.GATSBY_STRAPI_API_URL +
            images[(lightBoxIndex + images.length - 1) % images.length].url
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          clickOutsideToClose={true}
          onMovePrevRequest={() =>
            setLightBoxIndex(
              (lightBoxIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setLightBoxIndex((lightBoxIndex + 1) % images.length)
          }
        />
      )}
    </Container>
  );
};
