import clsx from 'clsx';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './card.module.css';

export interface ProjectCardProps {
  image: IGatsbyImageData;
  name: string;
}

export const ProjectCard = (props: ProjectCardProps) => {
  const { image, name } = props;
  return (
    <article className="group cursor-pointer relative rounded-2xl">
      <div className="image relative overflow-hidden rounded-xl shadow-2xl aspect-4/3">
        <GatsbyImage
          image={image}
          alt={name}
          imgClassName="rounded-xl overflow-hidden"
          className="h-full rounded-xl overflow-hidden"
        />
        <div className="overlay z-10 absolute top-0 bottom-0 right-0 left-0">
          <div
            className={clsx({
              [styles.overlay]: true,
              'bg-dark group-hover:opacity-0': true,
            })}
          />
        </div>
      </div>

      <div className="tag bg-dark rounded-lg px-4 md:px-8 py-2 md:py-4 flex absolute bottom-0 left-4 translate-y-1/2 z-20">
        <div className="name text-white text-sm md:text-base opacity-60 group-hover:opacity-100 transition-all duration-300 ease-out">
          {name}
        </div>
      </div>
    </article>
  );
};
