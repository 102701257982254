import React, { ReactNode } from 'react';

export interface ContactItemProps {
  icon?: ReactNode;
  label: string;
  href: string;
}

export interface ContactUsContactCardProps {
  title: string;
  description: string;
  contacts: ContactItemProps[];
}

export const ContactItem = ({ href, label, icon }: ContactItemProps) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex space-x-4 items-center"
  >
    <div className="icon text-primary font-bold text-lg items-center flex">
      {icon}
    </div>
    <div className="label">{label}</div>
  </a>
);

export const ContactUsContactCard = (props: ContactUsContactCardProps) => {
  const { title, description, contacts } = props;
  return (
    <article className="flex flex-col">
      <h4 className="text-primary text-2xl font-semibold">{title}</h4>
      <p className="font-ligth text-sm mt-2 text-secondaryText">
        {description}
      </p>
      <div className="items flex flex-col space-y-3 mt-6">
        {contacts.map((contact) => (
          <ContactItem key={contact.href} {...contact} />
        ))}
      </div>
    </article>
  );
};
