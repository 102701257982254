import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContactUsForm } from '../shared/ContactUsForm/ContactUsForm';

export interface MaintenanceContactSectionProps {}

export const MaintenanceContactSection = (
  props: MaintenanceContactSectionProps
) => {
  const {
    strapiMaintenance: { contacts },
  } = useStaticQuery(graphql`
    query getMaintananceContacts {
      strapiMaintenance {
        contacts {
          title
          description
          contactInfo {
            value
            type
          }
        }
      }
    }
  `);

  return (
    <ContactUsForm
      header={{
        title: 'Kontaktujte nás',
        subtitle:
          'Zametanie chodníkov ciest s použitím mechanických zametačov alebo v nedostupnejších miestach zamestnancami',
      }}
      category="maintenance"
      contacts={contacts}
      placeholder="Dobrý deň, mal by som záujem o správu a údržbu..."
    />
  );
};
