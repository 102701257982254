import React from 'react';

export interface TextAreaProps {
  label: string;
  name: string;
  placeholder?: string;
  className?: string;
}

export const TextArea = React.forwardRef((props: TextAreaProps, ref: any) => {
  const { label, name, placeholder, className, ...restOfProps } = props;
  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      <label
        htmlFor={`contact-form-${name}`}
        className="block text-xs text-white opacity-80 "
      >
        {label}
      </label>
      <textarea
        ref={ref}
        id={`contact-form-${name}`}
        name={name}
        rows={3}
        className="mt-2 focus:ring-primary focus:border-primary flex flex-1 w-full shadow-sm sm:text-sm border-dark rounded-md bg-opacity-5 bg-white text-white lg:text-md md:py-4 md:px-6 font-light"
        placeholder={placeholder}
        defaultValue={''}
        {...restOfProps}
      />
    </div>
  );
});
