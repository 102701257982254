import { graphql } from 'gatsby';
import { Layout } from '../components/layouts/Layout';
import ReactMarkdown from 'react-markdown';
import { ContentRenderer } from '../components/shared/ContentRenderer/ContentRenderer';
import { Container } from '@begbie/ui';
import { SectionWithHighlightedTitle } from '../components/shared/SectionWithHighlightedTitle/SectionWithHighlightedTitle';
import { StrapiSeo } from '@begbie/ui';

export interface PrivacyPolicyProps {
  data: any;
}

export const query = graphql`
  query PrivacyPolicyPageQuery {
    strapiPrivacyPolicy {
      pageTitle {
        subtitle
        title
        highlightedTitle
      }
      content {
        data {
          content
        }
      }
      seo {
       ...SeoFragment
     }
    }
  }
`;

export const PrivacyPolicyPage = (props: PrivacyPolicyProps) => {
  const { data } = props;

  return (
    <Layout>
      <StrapiSeo seo={data.strapiPrivacyPolicy.seo} />
      <SectionWithHighlightedTitle
        title={data?.strapiPrivacyPolicy.pageTitle.title}
        highlightedTitle={data?.strapiPrivacyPolicy.pageTitle.highlightedTitle}
        subtitle={data?.strapiPrivacyPolicy.pageTitle.subtitle}
      />
      <Container>
        <ContentRenderer
          source={data?.strapiPrivacyPolicy.content.data.content}
        />
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;