import React from 'react';
import loadable from '@loadable/component';
import { Container } from '../../container/Container';
import { StatisticSingle } from './components/SInleStatistic';
import { SingleStatistic } from './model/stats.model';

export interface StatsSectionProps {
  stats: SingleStatistic[];
}

export const StatsSection = (props: StatsSectionProps) => {
  const { stats } = props;
  return (
    <Container className=" mt-24 page-section grid grid-cols-2 xl:flex xl:flex-wrap xl:justify-center md:px-20 gap-8 md:gap-16 xl:gap-40 items-center">
      {stats.map((stat) => (
        <div className="flex md:justify-center" key={stat.label}>
          <StatisticSingle {...stat} />
        </div>
      ))}
    </Container>
  );
};
