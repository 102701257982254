import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React from "react";
import { StrapiSeoNamespace } from './seo.model';


export interface StrapiSeoProps {
  seo: StrapiSeoNamespace.Root
}

export const StrapiSeo = (props: StrapiSeoProps) => {
  const { seo } = props;
  return (
    <GatsbySeo
      title={`Begbie - ${seo.metaTitle}`}
      description={seo.metaDescription}
      canonical={seo?.canonicalURL}
      openGraph={{
        title: seo?.metaSocial[0]?.title,
        description: seo?.metaSocial[0]?.description,
        images: [
          {
            url: seo?.metaSocial[0]?.image?.localFile?.url,
            width: 800,
            height: 600,
            alt: seo?.metaSocial[0]?.image?.alternativeText,
          }
        ]
      }}
    />
  );
};
