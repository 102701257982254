import { FaQuoteLeft } from '@react-icons/all-files/fa/FaQuoteLeft';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';

export interface SeasonsQuoteCardProps {
  authorPrefix: string;
  author: string;
  logo: React.ReactNode;
  text: string;
  className?: string;
}

export const SeasonsQuoteCard = (props: SeasonsQuoteCardProps) => {
  const { author, authorPrefix, logo, text } = props;
  return (
    <Parallax speed={4} className={props.className}>
      <div className="quote">
        <div className="card bg-white z-10 rounded-lg shadow-xl py-4 px-8 xl:py-6 xl:px-16 overflow-hidden relative">
          <div className="who font-bold text-sm md:text-base">
            {authorPrefix} <span className="text-primary ml-1">{author}</span>
          </div>
          <div className="text text-secondaryText font-light text-xs lg:text-sm mt-1">
            {text}
          </div>

          <div className="who-logo absolute w-16 -top-2 -left-11 lg:w-28  lg:-top-2 lg:-left-20">
            {logo}
          </div>
        </div>
        <div className="apo lg:p-4 p-3 text-xs absolute -top-2 rounded-lg shadow-green -right-3 bg-primary text-white z-20">
          <FaQuoteLeft />
        </div>
      </div>
    </Parallax>
  );
};
