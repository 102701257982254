import React, { ReactNode } from 'react';

export interface SeasonLinkProps {
  href: string;
  title: string;
  icon: ReactNode;
}

export const SeasonLink = (props: SeasonLinkProps) => {
  const { href, title, icon } = props;
  return (
    <a href={href} className="group">
      <div className="flex items-center justify-center flex-col space-y-4">
        <div className="icon text-primary text-5xl xl:text-5xl lg:opacity-30 opacity-60 group-hover:opacity-60 duration-300">
          {icon}
        </div>
        <div className="title text-text opacity-40 lg:opacity-20 group-hover:opacity-50 duration-300">
          {title}
        </div>
      </div>
    </a>
  );
};
