import React from 'react';

export interface InfoSectionTitleProps {
  title: string;
}

export const InfoSectionTitle = (props: InfoSectionTitleProps) => {
  const { title } = props;
  return <h2 className=" text-2xl md:text-3xl text-dark font-medium inline-block mb-2">{title}</h2>;
};

export default InfoSectionTitle;