import { Container, SectionHeader, VehicleCard } from '@begbie/ui';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { generateOfferedVehicleRoute } from '../../../lib/routes';
import { useMaintenanceVehicles } from './hooks/useMaintananceVehicles';

export interface MaintananceVehiclesProps {}

export const MaintananceVehicles = (props: MaintananceVehiclesProps) => {
  const {} = props;
  const vehicles = useMaintenanceVehicles();

  return (
    <Container className="py-32" id="stroje">
      <SectionHeader
        title={'Stroje'}
        subtitle={`Zametanie chodníkov ciest s použitím mechanických zametačov alebo v nedostupnejších miestach zamestnancami`}
      />
      <div className="section grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-20 grid">
        {vehicles.map((vehicle) => {
          return (
            <Link
              to={generateOfferedVehicleRoute(vehicle.slug)}
              key={vehicle.name}
            >
              <VehicleCard
                {...vehicle}
                image={getImage(
                  (vehicle as any).image.localFile.childImageSharp
                    .gatsbyImageData
                )}
              />
            </Link>
          );
        })}
      </div>
    </Container>
  );
};
