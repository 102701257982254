import { SectionHeader, VehicleCard, VehicleCardProps } from "@begbie/ui";
import { Link } from "gatsby";
import React from "react";
import { generateOfferedVehicleRoute } from '../../../lib/routes';

export interface VehicleCategoryProps {
  name: string;
  vehicles: VehicleCardProps[];
}

export const VehicleCategory = (props: VehicleCategoryProps) => {
  const { name, vehicles } = props;
  return (
    <section>
      <SectionHeader title={name} />
      <div className="section grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-20 grid">
        {vehicles.map((vehicle) => {
          return (
            <Link
              key={vehicle.slug}
              to={generateOfferedVehicleRoute(vehicle.slug)}
            >
              <VehicleCard {...vehicle} />
            </Link>
          );
        })}
      </div>
    </section>
  );
};
