import { Container, SectionHeader } from '@begbie/ui';
import { Link } from 'gatsby';
import React from 'react';
import { generateConstructionProjectRoute } from './../../../lib/routes';
import { ProjectCard } from './components/ProjectCard';
import { useProjects } from './hooks/useProjects';

export interface OurProjectsProps {}

export const OurProjects = (props: OurProjectsProps) => {
  const {} = props;
  const ourProjects = useProjects();

  return (
    <section id="our-projects">
      <Container>
        <SectionHeader
          title={'Naše stavby'}
          subtitle={
            <>
              Vyberáme pre Vás malú vzorku zo všetkých nami realizovaných
              projektov.
            </>
          }
        />

        <div className="projects mt-12 lg:mt-16 grid gap-16 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {ourProjects.map((project) => (
            <Link
              to={generateConstructionProjectRoute(project.slug)}
              key={project.slug}
            >
              <ProjectCard key={project.name} {...project} />
            </Link>
          ))}
        </div>
      </Container>
    </section>
  );
};
