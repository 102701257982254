import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

export interface VehicleCardProps {
  slug: string;
  name: string;
  description: string;
  image: IGatsbyImageData;
}

export const VehicleCard = (props: VehicleCardProps) => {
  const { name, image, description } = props;
  return (
    <article className={'relative group'}>
      <div className={'w-full aspect-square '}>
        <GatsbyImage alt={name} image={getImage(image)} className="my-auto" />
      </div>
      <div
        className={
          'absolute left-0 -bottom-16 md:bottom-0 z-10 p-6 rounded-lg shadow-lg group-hover:shadow-2xl bg-white w-full md:w-3/4 flex flex-col space-y-2'
        }
      >
        <h3 className={'text-lg font-bold md:text-xl m-0'}>{name}</h3>
        <p className={'text-xs lg:text-sm text-secondaryText font-light'}>
          {description}
        </p>
        <div className="text-primary mt-2">Prenajať</div>
      </div>
    </article>
  );
};
