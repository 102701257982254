exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-firemne-udaje-tsx": () => import("./../../../src/pages/firemne-udaje.tsx" /* webpackChunkName: "component---src-pages-firemne-udaje-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ochrana-osobnych-udajov-tsx": () => import("./../../../src/pages/ochrana-osobnych-udajov.tsx" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-tsx" */),
  "component---src-pages-prenajom-vozidiel-index-tsx": () => import("./../../../src/pages/prenajom-vozidiel/index.tsx" /* webpackChunkName: "component---src-pages-prenajom-vozidiel-index-tsx" */),
  "component---src-pages-prenajom-vozidiel-strapi-offered-vehicle-slug-tsx": () => import("./../../../src/pages/prenajom-vozidiel/{StrapiOfferedVehicle.slug}.tsx" /* webpackChunkName: "component---src-pages-prenajom-vozidiel-strapi-offered-vehicle-slug-tsx" */),
  "component---src-pages-stavby-index-tsx": () => import("./../../../src/pages/stavby/index.tsx" /* webpackChunkName: "component---src-pages-stavby-index-tsx" */),
  "component---src-pages-stavby-strapi-project-slug-tsx": () => import("./../../../src/pages/stavby/{StrapiProject.slug}.tsx" /* webpackChunkName: "component---src-pages-stavby-strapi-project-slug-tsx" */),
  "component---src-pages-udrzba-index-tsx": () => import("./../../../src/pages/udrzba/index.tsx" /* webpackChunkName: "component---src-pages-udrzba-index-tsx" */)
}

