import { GallerySectionLoadable } from '@begbie/ui';
import React from 'react';
import { useConstructionProject } from '../providers/ConstructionProjectProvider';

export interface ConstructionProjectGallerySectionProps { }

export const ConstructionProjectGallerySection = (
  props: ConstructionProjectGallerySectionProps
) => {
  const { gallery } = useConstructionProject();
  return (
    <GallerySectionLoadable
      header={{
        title: gallery.header.title,
        subtitle: gallery.header.subtitle.data.subtitle,
      }}
      images={gallery.images.map((image) => image)}
    />
  );
};
