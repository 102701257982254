import React from 'react';

export interface LayoutConfigContext {
  headerColor: string;
}

export interface LayoutConfigContextProviderProps extends LayoutConfigContext {
  children: React.ReactNode | React.ReactNode[];
}

const LayoutConfigContext = React.createContext<LayoutConfigContext>(null);

export const useLayoutConfigContext = () => {
  const context = React.useContext(LayoutConfigContext);
  if (!context) {
    throw new Error(
      'useLayoutConfigContext must be used within a LayoutConfigContextProvider'
    );
  }
  return context;
};

export const LayoutConfigContextProvider = ({
  children,
  ...restOfProps
}: LayoutConfigContextProviderProps) => {
  return (
    <LayoutConfigContext.Provider value={restOfProps}>
      {children}
    </LayoutConfigContext.Provider>
  );
};
