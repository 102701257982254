import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { routes } from '../../../lib/routes';
import { RouterItem } from './components/RouterItem';

export interface RouterProps {}

export const Router = (props: RouterProps) => {
  return (
    <section className="h-screen flex flex-col lg:flex-row ">
      <RouterItem
        title="Stavby"
        href={routes.construction.to}
        image={
          <StaticImage
            quality={50}
            placeholder="blurred"
            src={'./assets/stavby-1.jpeg'}
            alt={'udrzba'}
          />
        }
      />
      <RouterItem
        title="Doprava"
        href={routes.vehicles.to}
        image={
          <StaticImage
            quality={50}
            placeholder="blurred"
            src={'./assets/doprava-2.jpeg'}
            alt={'Doprava'}
          />
        }
      />
      <RouterItem
        title="Údržba"
        href={routes.maintenance.to}
        image={
          <StaticImage
            quality={50}
            placeholder="blurred"
            src={'./assets/IMG_4509.jpeg'}
            alt={'udrzba'}
          />
        }
      />
    </section>
  );
};
