import { GrFacebookOption } from '@react-icons/all-files/gr/GrFacebookOption';
import { GrInstagram } from '@react-icons/all-files/gr/GrInstagram';
import { GrLinkedinOption } from '@react-icons/all-files/gr/GrLinkedinOption';
import { GrYoutube } from '@react-icons/all-files/gr/GrYoutube';
import React from 'react';

export interface FooterSocialsProps {}

const SocialLink = ({
  href,
  icon,
}: {
  href: string;
  icon: React.ReactNode;
}) => (
  <a
    href={href}
    className="flex social-link text-white text-xl opacity-40 hover:opacity-80 duration-200 p-3"
    target="_blank"
    rel="noopener noreferrer"
  >
    {icon}
  </a>
);

export const FooterSocials = (props: FooterSocialsProps) => {
  return (
    <section className="flex -ml-2 w-64">
      <SocialLink
        href={'https://facebook.com/Begbie-101541805597790'}
        icon={<GrFacebookOption />}
      />
      <SocialLink href={'https://www.instagram.com'} icon={<GrInstagram />} />
      <SocialLink
        href={'https://facebook.com/Begbie-101541805597790'}
        icon={<GrLinkedinOption />}
      />
      <SocialLink
        href={'https://facebook.com/Begbie-101541805597790'}
        icon={<GrYoutube />}
      />
    </section>
  );
};
