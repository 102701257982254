import { StrapiSeo } from '@begbie/ui';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Layout } from '../../components/layouts/Layout';
import { MaintananceHero } from '../../components/maintanance/hero/MaintenanceHero';
import { MaintenanceContactSection } from '../../components/maintanance/MaintenanceContactSection';
import { SeasonsLoadable } from '../../components/maintanance/Seasons/SeasonsLoadable';
import { MaintananceVehicles } from '../../components/maintanance/vehicles/MaintananceVehicles';

export interface MaintanancePageProps {}

const SeasonLinks = loadable(
  () =>
    import('../../components/maintanance/SeasonLinks/SeasonLinks').then(
      (mod) => mod.SeasonLinks
    ),
  {
    ssr: false,
  }
);

export const query = graphql`
  query MaintanancePageQuery {
    strapiMaintenance {
      seo {
        ...SeoFragment
      }
    }
  }
`;

export const MaintanancePage = (props: MaintanancePageProps) => {
  const {
    data: { strapiMaintenance },
  } = props;
  return (
    <Layout
      header={{
        cta: {
          label: 'Objednať si údržbu',
          to: '#contact-us-section',
        },
      }}
    >
      <StrapiSeo seo={strapiMaintenance.seo} />
      <ParallaxProvider>
        <MaintananceHero />
        <div id="seasons">
          <SeasonLinks />
        </div>
        <SeasonsLoadable />
        <MaintananceVehicles />
        <MaintenanceContactSection />
      </ParallaxProvider>
    </Layout>
  );
};

export default MaintanancePage;
