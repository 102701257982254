import { Container } from '@begbie/ui';
import React, { PureComponent } from 'react';

export interface SectionWithHighlightedTitleProps {
  title: string;
  highlightedTitle: string;
  subtitle?: string;
}
export const SectionWithHighlightedTitle = (
  props: SectionWithHighlightedTitleProps
) => {
  const { title, highlightedTitle, subtitle } = props;
  return (
    <section className="container mx-auto px-8 py-32 md:py-56 justify-center flex items-center relative 
    overflow-hidden flex-col">
        <header className="flex justify-center flex-col items-center">
          <h1 className="text-5xl md:text-6xl text-secondary  text-center font-bold">
            {title}
            <span className="text-primary text-5xl md:text-6xl text-center font-bold">{" "}{highlightedTitle}</span>
          </h1>
          <p className="mt-6 md:mt-8 text-lg font-light w-full md:w-2/3 text-center">
            {subtitle}
          </p>
        </header>
    </section>
  );
};
