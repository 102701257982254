import { Container, SectionHeader } from '@begbie/ui';
import React from 'react';
import { Contact } from '../../../lib/model/contact.model';
import { mapContactItemToContactItemProps } from '../../../lib/utils';
import { ContactForm } from './components/ContactForm';
import { ContactUsContactCard } from './components/ContactUsContactCard';

export interface ContactUsFormProps {
  header?: {
    title: string;
    subtitle?: string;
  };
  contacts: Contact[];
  category: string;
  placeholder?: string;
}

export const ContactUsForm = (props: ContactUsFormProps) => {
  const {
    contacts,
    header = {
      title: 'Kontaktujte nás',
      subtitle:
        'Zametanie chodníkov ciest s použitím mechanických zametačov alebo v nedostupnejších miestach zamestnancami',
    },
    category,
  } = props;

  return (
    <section
      className="page-section relative flex mb-4 py-24 md:py-32"
      id="contact-us-section"
    >
      <Container className="flex lg:space-x-24 flex-col space-y-16 lg:space-y-0 lg:flex-row">
        <div className="info flex flex-1 flex-col">
          <SectionHeader {...header} />
          <div className="contact-items grid grid-cols-1 md:grid-cols-2 gap-10">
            {contacts.map((contact, index) => (
              <ContactUsContactCard
                key={contact.title}
                title={contact.title}
                description={contact.description}
                contacts={contact.contactInfo.map(
                  mapContactItemToContactItemProps
                )}
              />
            ))}
          </div>
        </div>
        <div className="form flex flex-1 z-10 rounded-2xl shadow-2xl bg-dark lg:bg-none">
          <ContactForm
            category={category}
            messagePlaceholder={props.placeholder}
          />
        </div>
      </Container>
    </section>
  );
};
