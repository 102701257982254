import { VehicleCardProps } from '@begbie/ui';
import { graphql, useStaticQuery } from 'gatsby';

export interface VehicleCategory {
  name: string;
  offered_vehicles: VehicleCardProps[]
}

export const useOfferedVehicles = (): VehicleCategory[] => {
  const {
    allStrapiVehicleCategory: { nodes },
  } = useStaticQuery(graphql`
    query getOfferedVehicles {
      allStrapiVehicleCategory {
        nodes {
          name
          offered_vehicles {
            name
            slug
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 40
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes
};
