import { Link } from 'gatsby';
import React from 'react';
import './router-item.scss';

export interface RouterItemProps {
  title: string;
  href: string;
  image: JSX.Element;
}

export const RouterItem = (props: RouterItemProps) => {
  const { title, href, image } = props;
  return (
    <article className="group flex flex-1 overflow-hidden relative cursor-pointer">
      {/* Image */}
      <div className="image flex flex-1 absolute top-0 bottom-0 left-0 right-0">
        {image}
      </div>
      {/* Overlay  */}
      <div className="overlay   absolute top-0 bottom-0 left-0 right-0 router-item opacity-80 group-hover:opacity-60 ease-in-out duration-500"></div>
      {/* Content */}
      <Link to={href} className=" ">
        <div className="content absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
          <div className="title text-2xl lg:text-4xl font-bold opacity-60 group-hover:opacity-100 ease-in-out duration-300">
            <div className="value text-white inline">{title}</div>
            <div className="dot text-primary inline">.</div>
          </div>
        </div>
      </Link>
    </article>
  );
};
